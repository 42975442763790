export default {
  methods: {
    getFieldsForSubmit (fields) {
      const data = {}

      fields.forEach((field) => {
        data[field.name] = field.value
      })

      return data
    }
  }
}
