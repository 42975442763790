<template>
  <section>
    <h2>
      Расскажите о вашем проекте
    </h2>

    <h3>
      Ваши личные данные
    </h3>

    <div class="inputs__container">
      <input-with-label v-for="(input,index) in personalDataInputs"
                        :input="input"
                        :key="`formFieldPersonal${index}`"
                        @inputValue="inputValue($event, input)"/>
    </div>
  </section>
</template>

<script>
import InputWithLabel from './InputWithLabel'
import submitAppealFields from '@/mixins/submitAppealFields'

export default {
  name: 'SecondStep',

  props: {
    projectAppealData: {
      type: Object,
      required: false,
      default: null
    }
  },

  data () {
    return {
      personalDataInputs: [
        {
          name: 'organization_name',
          label: 'Ваша организация',
          placeholder: 'Название организации',
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'organization_site',
          label: 'Сайт организации',
          placeholder: 'Ваш сайт',
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'organization_email',
          label: 'E-mail организации',
          placeholder: 'E-mail организации',
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'organization_position',
          label: 'Ваша должность',
          placeholder: 'Ваша должность',
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'organization_employees_count',
          label: 'Сколько сотрудников работает сейчас в организации на постоянной основе?',
          placeholder: 'Количество сотрудников',
          type: 'number',
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'organization_responsibilities',
          label: 'Ваши функциональные обязанности',
          placeholder: 'Ваши обязанности',
          style: {
            width: '62.5rem',
            marginRight: 0
          },
          isRequired: true,
          isValid: true,
          value: null
        }
      ]
    }
  },

  mounted () {
    this.setValues()
  },

  watch: {
    projectAppealData: {
      deep: true,
      handler () {
        this.setValues()
      }
    }
  },

  methods: {
    setValues () {
      if (this.projectAppealData.organization) {
        for (const key in this.projectAppealData.organization) {
          this.personalDataInputs = this.personalDataInputs.map((input) => {
            return {
              ...input,
              value: (input.name === `organization_${key}`) ? this.projectAppealData.organization[key] : input.value
            }
          })
        }

        this.personalDataInputs = this.personalDataInputs.map((input) => {
          return {
            ...input,
            value: this.projectAppealData[input.name] ? this.projectAppealData[input.name] : input.value
          }
        })
      }
    },

    inputValue (value, input) {
      input.value = value
    },

    emitData () {
      this.$emit('emitData', Object.assign(
        this.getFieldsForSubmit(this.personalDataInputs)
      ))
    },

    validate () {
      this.personalDataInputs = this.personalDataInputs.map((input) => {
        return {
          ...input,
          isValid: (input.isRequired && !!input.value) || !input.isRequired
        }
      })

      if (this.personalDataInputs.some((input) => !input.isValid)) {
        return false
      }

      return true
    }
  },

  components: {
    InputWithLabel
  },

  mixins: [submitAppealFields]
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  section {
    display: flex;
    flex-direction: column;

    > .comment__text {
      width: 40.93rem;
      font-size: .875rem;
      line-height: 1.375rem;
      color: $mainFontColor;
      margin-bottom: 1.25rem;
    }

    > .check-box__container {
      display: flex;
      align-items: center;
      margin-bottom: 2.5rem;

      > input[type="text"] {
        width: 62.5rem;
        margin-left: auto;
      }

      > label {
        margin: .1rem 2rem 0 0;
        font-size: .75rem;
        line-height: 1.125rem;
        color: #003E78;
        opacity: .6;
      }

      > input[type="radio"] {
        margin-right: .75rem;
        width: 1.25rem;
        height: 1.25rem;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: white;
          border-radius: 50%;
          border: .09rem solid $neutral-secondary;
        }

        &:checked:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          background-color: white;
          border-radius: 50%;
          border: .375rem solid $primary-color;
        }
      }
    }
  }
</style>
