<template>
  <form>
    <h2>
      Заполните заявку
    </h2>

    <h3>
      Ваши личные данные
    </h3>

    <div class="inputs__container">
      <input-with-label v-for="(input,index) in personalDataInputs"
                        :input="input"
                        :key="`formFieldPersonal${index}`"
                        @inputValue="inputValue($event, input)"/>
    </div>

    <h3>
      Информация о проекте
    </h3>

    <div class="inputs__container">
      <input-with-label v-for="(input,index) in projectInfoInputs.slice(0,projectInfoInputs.length-1)"
                        :input="input"
                        :key="`0formFieldProjectInfo${index}`"
                        @inputValue="inputValue($event, input)"/>
    </div>

    <p class="comment__text">
      Подавали ли Вы заявку на Премию «Я — гражданин» в 2014, 2015 и 2016 годах и на конкурс «Мой проект – моей стране!» в 2017, 2018, 2019, 2020 годах? Если да, напишите год и номинацию.
    </p>

    <div class="check-box__container">
      <input type="radio" name="radio" id="yes_radio" :value="true" v-model="isComment">
      <label for="yes_radio">Да</label>
      <input type="radio" name="radio" id="no_radio" :value="false" v-model="isComment">
      <label for="no_radio">Нет</label>

      <input v-if="isComment" v-model="projectInfoInputs[projectInfoInputs.length-1].value" type="text" id="comment" name="comment" placeholder="Ваш комментарий" class="input blue">
    </div>

  </form>
</template>

<script>
import InputWithLabel from './InputWithLabel'
import submitAppealFields from '@/mixins/submitAppealFields'

export default {
  name: 'FirstStep',

  props: {
    projectAppealData: {
      type: Object,
      required: false,
      default: null
    }
  },

  data () {
    return {
      isComment: true,
      personalDataInputs: [
        {
          name: 'personal_last_name',
          label: 'Фамилия',
          placeholder: 'Ваша фамилия',
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'personal_first_name',
          label: 'Имя',
          placeholder: 'Ваше имя',
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'personal_patronymic',
          label: 'Отчество',
          placeholder: 'Ваше отчество',
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'personal_birthday_date',
          label: 'Дата рождения',
          type: 'date',
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'personal_passport_number',
          label: 'Серия и номер паспорта',
          placeholder: 'ХХХХХХХХХХ',
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'personal_birthday_place',
          label: 'Место рождения',
          placeholder: 'Страна, город, улица, дом',
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'personal_email',
          label: 'E-mail',
          type: 'email',
          placeholder: 'Ваш e-mail',
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'personal_phone',
          label: 'Номер телефона',
          placeholder: '+7 _ _ _ - _ _ _ - _ _ - _ _',
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'face_type',
          label: 'Статус: физическое/юридическое лицо',
          placeholder: 'Выберите статус',
          type: 'select',
          list: [
            {
              code: 'PHYSICAL',
              id: 'PHYSICAL',
              order: null,
              title: 'физ. лицо'
            },
            {
              code: 'LEGAL',
              id: 'LEGAL',
              order: null,
              title: 'юр. лицо'
            }
          ],
          isRequired: true,
          isValid: true
        }
      ],
      projectInfoInputs: [
        {
          name: 'personal_federal_district_id',
          label: 'ФО реализации проекта',
          placeholder: 'Выберите федеральный округ',
          type: 'select',
          list: [],
          isRequired: true,
          isValid: true
        },
        {
          name: 'personal_region_id',
          label: 'Регион проживания',
          placeholder: 'Выберите регион',
          type: 'select',
          list: [],
          isRequired: true,
          isValid: true
        },
        {
          name: 'personal_city',
          label: 'Город или населенный пункт',
          placeholder: 'Ваш город или населенный пункт',
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'nomination_id',
          label: 'Направление Вашей деятельности',
          placeholder: 'Выберите направление',
          type: 'select',
          list: [],
          isRequired: true,
          isValid: true
        },
        {
          name: 'personal_role',
          label: 'Ваша роль в проектной команде',
          placeholder: 'Ваша роль',
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'personal_source',
          label: 'Откуда Вы узнали о конкурсе?',
          placeholder: 'Напишите источник',
          isRequired: false,
          isValid: true,
          value: null
        },
        {
          name: 'project_date',
          label: 'Дата начала проекта',
          placeholder: 'Напишите дату начала проекта',
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'personal_previous_application',
          label: 'Подавали ли Вы заявку',
          placeholder: 'Ваш комментарий',
          isRequired: false,
          isValid: true,
          value: null
        }
      ]
    }
  },

  mounted () {
    this.setValues()
    this.$store.dispatch('setListDistricts')
    this.$store.dispatch('setListRegions')
    this.$store.dispatch('setNomination')
  },

  watch: {
    projectAppealData: {
      deep: true,
      handler () {
        this.setValues()
      }
    },

    '$store.getters.getListDistricts' () {
      this.projectInfoInputs[0].list = this.$store.getters.getListDistricts
    },

    '$store.getters.getListRegions' () {
      this.projectInfoInputs[1].list = this.$store.getters.getListRegions
    },

    '$store.getters.getNomination' () {
      this.projectInfoInputs[3].list = this.$store.getters.getNomination
    }
  },

  methods: {
    setValues () {
      if (this.projectAppealData.personal) {
        for (const key in this.projectAppealData.personal) {
          this.personalDataInputs = this.personalDataInputs.map((input) => {
            return {
              ...input,
              value: (input.name === `personal_${key}`) ? this.projectAppealData.personal[key] : input.value
            }
          })
          this.projectInfoInputs = this.projectInfoInputs.map((input) => {
            if (input.name === `personal_${key}`) {
              return {
                ...input,
                value: this.projectAppealData.personal[key]
              }
            } else {
              return input
            }
          })
        }

        this.personalDataInputs = this.personalDataInputs.map((input) => {
          return {
            ...input,
            value: this.projectAppealData[input.name] ? this.projectAppealData[input.name] : input.value
          }
        })

        this.projectInfoInputs = this.projectInfoInputs.map((input) => {
          return {
            ...input,
            value: this.projectAppealData[input.name] ? this.projectAppealData[input.name] : input.value
          }
        })

        this.projectInfoInputs[3].value = this.projectAppealData.nomination_id

        this.projectInfoInputs[6].value = this.projectAppealData.project_date

        this.personalDataInputs[8].value = this.projectAppealData.face_type

        if (this.projectAppealData.personal.previous_application) {
          this.isComment = true
        } else {
          this.isComment = false
        }
      }
    },

    inputValue (value, input) {
      input.value = value
    },

    emitData () {
      this.$emit('emitData', Object.assign(
        this.getFieldsForSubmit(this.personalDataInputs),
        this.getFieldsForSubmit(this.projectInfoInputs)
      ))
    },

    validate () {
      this.personalDataInputs = this.personalDataInputs.map((input) => {
        return {
          ...input,
          isValid: (input.isRequired && !!input.value) || !input.isRequired
        }
      })

      this.projectInfoInputs = this.projectInfoInputs.map((input) => {
        return {
          ...input,
          isValid: (input.isRequired && !!input.value) || !input.isRequired
        }
      })

      if (this.personalDataInputs.some((input) => !input.isValid)) {
        return false
      }

      if (this.projectInfoInputs.some((input) => !input.isValid)) {
        return false
      }

      return true
    }
  },

  components: {
    InputWithLabel
  },

  mixins: [submitAppealFields]
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  form {
    display: flex;
    flex-direction: column;

    > .comment__text {
      width: 40.93rem;
      font-size: .875rem;
      line-height: 1.375rem;
      color: $mainFontColor;
      margin-bottom: 1.25rem;
    }

    > .check-box__container {
      display: flex;
      align-items: center;
      margin-bottom: 2.5rem;

      > input[type="text"] {
        width: 62.5rem;
        margin-left: auto;
      }

      > label {
        margin: .1rem 2rem 0 0;
        font-size: .75rem;
        line-height: 1.125rem;
        color: #003E78;
        opacity: .6;
      }

      > input[type="radio"] {
        margin-right: .75rem;
        width: 1.25rem;
        height: 1.25rem;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: white;
          border-radius: 50%;
          border: .09rem solid $neutral-secondary;
        }

        &:checked:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          background-color: white;
          border-radius: 50%;
          border: .375rem solid $primary-color;
        }
      }
    }
  }
  @media screen and (max-width: 768px){
    form {
      > .comment__text{
        width: 100%;
      }

      > .check-box__container {
        flex-wrap: wrap;
        > input[type="text"]{
          margin-top: 1rem;
        }
      }
    }
  }
</style>
