<template>
  <div class="time-line__container">
    <time-line-point v-for="(step,index) in getPassedSteps" :index="step.index" :is-passed="true" :key="`timeLinePassedStep${index}`" class="passed__steps"/>
    <p class="current-step__title">
      {{ getCurrentStep.title }}
    </p>

    <span class="horizontal-line"></span>

    <time-line-point v-for="(step,index) in getComingSteps" :index="step.index" :key="`timeLineComingStep${index}`"/>
  </div>
</template>

<script>
import TimeLinePoint from './TimeLinePoint'

export default {
  name: 'AppealTimeLine',
  props: {
    steps: {
      type: Object,
      required: true
    }
  },
  computed: {
    getPassedSteps () {
      return this.steps.list.filter((step, index) => index <= this.steps.current)
    },
    getComingSteps () {
      return this.steps.list.filter((step, index) => index > this.steps.current)
    },
    getCurrentStep () {
      return this.steps.list[this.steps.current]
    }
  },
  components: {
    TimeLinePoint
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  .time-line__container {
    margin-bottom: 2.5rem;
    margin-left: -.75rem;
    display: flex;
    /*justify-content: space-between;*/
    justify-content: flex-start;
    align-items: center;

    > .current-step__title {
      font-weight: 600;
      font-size: .875rem;
      line-height: 1.375rem;
      color: $mainFontColor;
      margin-right: 2rem;
      margin-left: .5rem;
    }

    > .horizontal-line {
      height: .06rem;
      /*width: 100%;*/
      flex-grow: 1;
      background-color: #D6E6F5;
    }
  }
</style>
