<template>
  <div>
    <bread-crumb :navigationArray="navigationLink"/>

    <div class="container">
      <h1>
        Карта проекта
      </h1>
    </div>

    <div class="white-container">
      <p class="link__text">Если у вас возникли технические проблемы при подаче заявки, просим заполнить <a href="https://forms.gle/Nqu1NFzB9x3hykD87">эту форму.</a></p>
    </div>

    <div class="white-container">
      <p class="link__text">Сохраняйте черновик перед переходом на следующий этап для обеспечения сохранности данных.</p>
    </div>

    <br/>

    <div class="white-container">
      <appeal-time-line :steps="steps"/>

      <component :is="steps.list[steps.current].component"
                 ref="currentStep"
                 :projectAppealData="submitData"
                 @emitData="emitData($event)"/>

      <div class="buttons__container">
        <button class="btn-white" @click="changeCurrentStep(false)" v-if="!isFirst">
          Отмена
        </button>

        <button class="btn-white save-appeal-button" @click="saveAppeal()" :disabled="getIsSubmitted">
          <img src="/assets/icons/edit_pencil_blue.svg" alt="" class="download-icon">
          Сохранить черновик
        </button>

        <button class="btn-blue" @click="changeCurrentStep(true)" v-if="steps.current < 3">
          Далее
        </button>

        <button class="btn-blue" @click="submitAppeal" v-else :disabled="getIsSubmitted">
          Завершить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
import AppealTimeLine from '@/components/projectSubmitAppeal/AppealTimeLine'
import FirstStep from '@/components/projectSubmitAppeal/FirstStep'
import SecondStep from '@/components/projectSubmitAppeal/SecondStep'
import ThirdStep from '@/components/projectSubmitAppeal/ThirdStep'
import FourthStep from '@/components/projectSubmitAppeal/FourthStep'

export default {
  name: 'SubmitAppeal',

  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Карта проекта'
        }
      ],
      steps: {
        current: 0,
        list: [
          {
            title: 'Заявка',
            component: 'FirstStep',
            index: 0
          },
          {
            title: 'Об организации',
            component: 'SecondStep',
            index: 1
          },
          {
            title: 'О деятельности',
            component: 'ThirdStep',
            index: 2
          },
          {
            title: 'О проекте',
            component: 'FourthStep',
            index: 3
          }
        ]
      },
      submitData: {},
      isFirst: true
    }
  },

  watch: {
    '$store.getters.getPeriod' () {
      if (!this.$store.getters.getPeriod.some(period => period.is_active)) {
        this.$router.push('/')
        this.$store.commit('setMessage', {
          type: 'error',
          text: 'Нет активного тура для заполнения заявки'
        })
      }
    },
    '$store.getters.getProjectAppealData' () {
      this.submitData = this.$store.getters.getProjectAppealData
      this.isFirst = false
    },
    getPersonalData: {
      immediate: true,
      handler () {
        if (!this.getPersonalData.project_appeal) {
          // this.$router.push('/')
        } else if (this.getPersonalData.project_appeal.status_code === 'DRAFT') {
          this.$router.push(`/project-submit-appeal/${this.getPersonalData.project_appeal.id}/edit`)
          this.$store.dispatch('getProjectAppealData', this.getPersonalData.project_appeal.id)
        } else {
          this.$router.push(`/project-map/${this.getPersonalData.project_appeal.id}`)
        }
      }
    }
  },

  created () {
    this.$store.dispatch('setPeriod')
    if (!localStorage.getItem('token')) {
      this.$router.push('/')
      this.$store.commit('setMessage', {
        type: 'error',
        text: 'Для заполнения заявки необходимо авторизоваться'
      })
    } else {
      this.$store.dispatch('getPersonalData')
    }
    if (this.isEdit) {
      this.$store.dispatch('getProjectAppealData', this.$route.params.id)
    }
  },

  methods: {
    changeCurrentStep (offset) {
      this.$refs.currentStep.emitData()

      if (offset) {
        if (this.$refs.currentStep.validate() && this.steps.current < 3) {
          this.steps.current++
        }
      } else if (this.steps.current > 0) {
        this.steps.current--
      } else {
        this.$router.push('/personal-account')
      }
    },

    emitData (value) {
      this.submitData = Object.assign(this.submitData, value)
    },

    saveAppeal () {
      this.$refs.currentStep.emitData()

      const formData = new FormData()

      for (const key in this.submitData) {
        if (this.submitData[key] !== null && this.submitData[key] !== undefined) {
          if (['activity_photo', 'description_photo', 'description_gallery'].includes(key)) {
            this.submitData[key].forEach((file, index) => {
              if (typeof file === 'string') {
                let filename = file.split('/')
                filename = filename[filename.length - 1]
                formData.append(`${key}[${index}]`, filename)
              } else {
                formData.append(`${key}[${index}]`, file)
              }
            })
          } else {
            formData.append(key, this.submitData[key])
          }
        }
      }

      if (this.isEdit) {
        formData.append('_method', 'PUT')

        this.$store.dispatch('updateProjectAppeal', {
          id: this.$route.params.id,
          formData: formData
        })
      } else {
        this.$store.dispatch('saveProjectAppeal', formData)
      }
    },

    submitAppeal () {
      this.$refs.currentStep.emitData()

      if (this.$refs.currentStep.validate()) {
        const formData = new FormData()

        for (const key in this.submitData) {
          if (this.submitData[key] !== null && this.submitData[key] !== undefined) {
            if (['activity_photo', 'description_photo', 'description_gallery'].includes(key)) {
              this.submitData[key].forEach((file, index) => {
                if (typeof file === 'string') {
                  let filename = file.split('/')
                  filename = filename[filename.length - 1]
                  formData.append(`${key}[${index}]`, filename)
                } else {
                  formData.append(`${key}[${index}]`, file)
                }
              })
            } else {
              formData.append(key, this.submitData[key])
            }
          }
        }

        if (this.isEdit) {
          formData.append('appeal_id', this.$route.params.id)
        }

        this.$store.dispatch('submitProjectAppeal', formData)
      }
    }
  },

  computed: {
    getPersonalData () {
      return this.$store.getters.getPersonalData
    },
    getIsSubmitted () {
      return this.$store.getters.getIsSubmitted
    }
  },

  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    AppealTimeLine,
    BreadCrumb
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  .btn-blue:disabled, .btn-white:disabled {
    opacity: .3;
  }

  .link__text {
    font-size: .9rem;

    a {
      color: $primary-color;
    }
  }

  h1 {
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.75rem;
    color: $mainFontColor;
    margin-bottom: 2rem;
  }

  .white-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    > .buttons__container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 2.5rem;
      border-top: .06rem solid $neutral-secondary;

      > .save-appeal-button {
        margin-left: auto;
        margin-right: 1.25rem;
      }
    }
  }

  ::v-deep .inputs__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
    margin-bottom: 1rem;
    align-items: flex-end;

    > * {
      margin-bottom: 1.5rem;
      margin-right: 2.187rem;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }

  ::v-deep h2 {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: $mainFontColor;
    margin-bottom: 1.25rem;
  }

  ::v-deep h3 {
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.375rem;
    color: $mainFontColor;
    margin-bottom: 1.25rem;
  }
  @media screen and (max-width: 420px){
    .btn-white{
      margin-right: 1.25rem;
      display: flex;
      align-items: center;
    }
  }
</style>
