<template>
  <section>
    <h2>
      Расскажите о деятельности вашей организации
    </h2>

    <div class="inputs__container">
      <input-with-label v-for="(input,index) in organizationActivityInputs.slice(0,10)" :input="input"
          :key="`formFieldOrganizationActivityInputs1${index}`" @inputValue="inputValue($event, input)"/>

      <!-- <input-drop-zone :drop-zone="dropZones[0]"/> -->
      <p>Прикрепите документы</p>
      <div :class="['block_file', {'not-valid__block_file': !dropZones[0].isValid}]">
          <vue-dropzone ref="myVueDropzone0" id="customdropzone1"
                        :options="dropzoneOptions"
                        :useCustomSlot="true"
                        :value="dropZones[0].value"
                        :include-styling="false"
                        @vdropzone-file-added="vfileAdded($event, 0)"
                        @vdropzone-removed-file="removedFile($event, 0)"
                        @vdropzone-thumbnail="thumbnail">
              <div class="admin_dropzone" v-show="!dropZones[0].value.length">
                  <div class="iconDropZone"></div>
                  <p class="textDropZone">Перетащите файлы с компьютера или нажмите кнопку загрузить</p>
                  <span class="docDropZone"> (форматы png, jpg, pdf)</span>
                  <button class="buttonDropZone">Загрузить</button>
              </div>
          </vue-dropzone>
      </div>

      <!-- <input-drop-zone :drop-zone="dropZones[1]"/> -->
      <p>Прикрепите фото реализации проекта</p>
      <div :class="['block_file', {'not-valid__block_file': !dropZones[1].isValid}]">
          <vue-dropzone ref="myVueDropzone1" id="customdropzone2"
                        :options="dropzoneOptions"
                        :useCustomSlot="true"
                        :value="dropZones[1].value"
                        :include-styling="false"
                        @vdropzone-file-added="vfileAdded($event, 1)"
                        @vdropzone-removed-file="removedFile($event, 1)"
                        @vdropzone-thumbnail="thumbnail">
            <div class="admin_dropzone" v-show="!dropZones[1].value.length">
                  <div class="iconDropZone"></div>
                  <p class="textDropZone">Перетащите файлы с компьютера или нажмите кнопку загрузить</p>
                  <span class="docDropZone"> (форматы png, jpg, pdf)</span>
                  <button class="buttonDropZone">Загрузить</button>
              </div>
          </vue-dropzone>
      </div>

      <input-with-label v-for="(input,index) in organizationActivityInputs.slice(10)" :input="input"
          :key="`formFieldOrganizationActivityInputs2${index}`" @inputValue="inputValue($event, input)"/>
    </div>
  </section>
</template>

<script>
import InputWithLabel from './InputWithLabel'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import submitAppealFields from '@/mixins/submitAppealFields'

export default {
  name: 'FourthStep',

  props: {
    projectAppealData: {
      type: Object,
      required: false,
      default: null
    }
  },

  data () {
    return {
      dropzoneOptions: {
        url: '/api/upload-file',
        thumbnailWidth: 150,
        maxFilesize: 200,
        maxFiles: 10,
        addRemoveLinks: true,
        parallelUploads: 1,
        headers: { 'Content-Type': 'multipart/form-data' },
        paramName: 'file',
        previewTemplate: this.template(),
        accept: function (file, done) {
          if (file.name !== 0) {
          } else {
            done()
          }
        }
      },
      organizationActivityInputs: [
        {
          name: 'description_name',
          label: 'Название проекта',
          placeholder: 'Название проекта',
          style: {
            width: '32%',
            marginRight: '2%'
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'description_site',
          label: 'Ссылка на сайт проекта',
          placeholder: 'Ссылка на сайт',
          style: {
            width: '32%',
            marginRight: '2%'
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'description_description',
          label: 'Кратко опишите проект: цели, задачи, формат реализации и географию проекта',
          placeholder: 'Ваш комментарий',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'description_direction',
          label: 'Опишите, на кого направлен Ваш проект?',
          placeholder: 'Ваш комментарий',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'description_solves',
          label: 'Укажите, на решение каких социальных проблем направлена деятельность Вашего проекта',
          placeholder: 'Ваш комментарий',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'description_profile_instagram',
          label: 'Ваш профиль в ВКонтакте',
          placeholder: 'https://vk.com/name',
          style: {
            width: '32%',
            marginRight: '2%'
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'description_profile_facebook',
          label: 'Ваш профиль в Facebook',
          placeholder: 'https://facebook.com/name',
          style: {
            width: '32%',
            marginRight: '2%'
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'description_profile_other',
          label: 'Другая соц. сеть',
          placeholder: 'Одноклассники, Instagram, Twitter',
          style: {
            width: '32%',
            marginRight: 0
          },
          isRequired: false,
          isValid: true,
          value: null
        },
        {
          name: 'description_release_stage',
          label: 'На какой стадии реализации находится Ваш проект?',
          placeholder: 'Выберите стадию',
          type: 'select',
          list: [
            {
              code: 'PLANNING',
              id: 'PLANNING',
              order: null,
              title: 'планирование проекта'
            },
            {
              code: 'REALIZATION',
              id: 'REALIZATION',
              order: null,
              title: 'реализация проекта'
            },
            {
              code: 'REALIZED',
              id: 'REALIZED',
              order: null,
              title: 'проект реализован'
            }
          ],
          isRequired: true,
          isValid: true,
          style: {
            width: '32%',
            marginRight: 0
          }
        },
        {
          name: 'description_release_term',
          label: 'Сроки реализации проекта?',
          placeholder: 'Сроки реализации проекта',
          style: {
            width: '68%',
            marginRight: 0
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        // TODO : two dropzone
        {
          name: 'description_activity_differences',
          label: 'Чем деятельность Вашего проекта отличается от деятельности подобных проектов?',
          placeholder: 'Ваш комментарий',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'description_strategy',
          label: 'Опишите стратегию развития и план реализации проекта?',
          placeholder: 'Ваш комментарий',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'description_communication_channels',
          label: 'Опишите каналы коммуникации с Вашей аудиторией (где и как Вы общаетесь с Вашими целевыми группами)',
          placeholder: 'Ваш комментарий',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'description_results',
          label: 'Перечислите наиболее значимые на Ваш взгляд результаты, которые Вы достигли в процессе реализации проектов, и за какой период времени',
          placeholder: 'Ваш комментарий',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'description_staff',
          label: 'Укажите сколько человек работает над реализацией проекта? Их ФИО, должность и функционал',
          placeholder: 'Ваш комментарий',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'description_volunteers',
          label: 'Привлекаете ли Вы к работе волонтеров? Если да, то какое количество и на какие задачи',
          placeholder: 'Ваш комментарий',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'description_financing',
          label: 'Источники финансирования. На какие средства существует организация/проект?',
          placeholder: 'Ваш комментарий',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'description_competitions_appeals',
          label: 'Подавали ли Вы ваш проект на премию и конкурсы? Если да, то укажите на какие именно и конечный результат',
          placeholder: 'Ваш комментарий',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'description_recommendations',
          label: 'Кто может дать рекомендации вашему проекту? Укажите ФИО, должность рекомендателей и их контакты',
          placeholder: 'Ваш комментарий',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'description_comment',
          label: 'Ваши комментарии (что еще вы считаете важным сообщить?)',
          placeholder: 'Ваш комментарий',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: true,
          isValid: true,
          value: null
        }
      ],
      dropZones: [
        {
          name: 'description_photo',
          label: 'Прикрепите Ваше фото',
          isRequired: false,
          isValid: true,
          formats: '(форматы jpg, pdt, ppt, docx. xls)',
          value: []
        },
        {
          name: 'description_gallery',
          label: 'Фотогалерея',
          isRequired: false,
          isValid: true,
          formats: '(материалы в формате jpg не более 5мб)',
          value: []
        }
      ]
    }
  },

  mounted () {
    this.setValues()
  },

  watch: {
    projectAppealData: {
      deep: true,
      handler () {
        this.setValues()
      }
    }
  },

  methods: {
    setValues () {
      if (this.projectAppealData.description) {
        for (const key in this.projectAppealData.description) {
          this.organizationActivityInputs = this.organizationActivityInputs.map((input) => {
            return {
              ...input,
              value: (input.name === `description_${key}`) ? this.projectAppealData.description[key] : input.value
            }
          })
          this.dropZones = this.dropZones.map((input, index) => {
            if (input.name === `description_${key}`) {
              return {
                ...input,
                value: this.projectAppealData.description[key] ? this.projectAppealData.description[key].map((file) => {
                  const url = file
                  let fileName = url.split('/')

                  fileName = fileName[fileName.length - 1]

                  let extension = fileName.split('.')
                  extension = extension[extension.length - 1]

                  this.$refs[`myVueDropzone${index}`].manuallyAddFile({
                    size: 123,
                    name: fileName,
                    type: extension,
                    accepted: true,
                    file_url: url
                  }, url)
                  return url
                }) : []
              }
            } else {
              return input
            }
          })
        }

        this.organizationActivityInputs = this.organizationActivityInputs.map((input) => {
          return {
            ...input,
            value: this.projectAppealData[input.name] ? this.projectAppealData[input.name] : input.value
          }
        })

        this.dropZones = this.dropZones.map((input) => {
          return {
            ...input,
            value: this.projectAppealData[input.name] ? this.projectAppealData[input.name] : input.value
          }
        })
      }
    },

    inputValue (value, input) {
      input.value = value
    },

    emitData () {
      this.$emit('emitData', Object.assign(
        this.getFieldsForSubmit(this.organizationActivityInputs),
        this.getFieldsForSubmit(this.dropZones)
      ))
    },

    vfileAdded (file, i) {
      var files = this.dropZones[i].value
      files.push(file)
      this.dropZones[i].value = files
    },

    removedFile (file, i) {
      var array = this.dropZones[i].value
      var newArr = []
      array.forEach(item => {
        if ('upload' in file) {
          if (file.upload.uuid !== item.upload.uuid) {
            newArr.push(item)
          }
        } else if ('file_url' in file) {
          if (file.file_url !== item) {
            newArr.push(item)
          }
        }
      })
      /*
      if (newArr.length === 0) {
        document.getElementsByClassName('iconDropZone')[i].style.display = 'block'
        document.getElementsByClassName('textDropZone')[i].style.display = 'block'
        document.getElementsByClassName('docDropZone')[i].style.display = 'block'
      }
      */
      this.dropZones[i].value = newArr
    },

    template () {
      return `<div class="dz-preview dz-file-preview">
                <div class="dz-image">
                    <div data-dz-thumbnail-bg></div>
                </div>
            </div>`
    },

    thumbnail (file, dataUrl) {
      var j, len, ref, thumbnailElement
      if (file.previewElement) {
        /*
        document.getElementById('iconDropZone').style.display = 'none'
        document.getElementById('textDropZone').style.display = 'none'
        document.getElementById('docDropZone').style.display = 'none'
        */
        file.previewElement.classList.remove('dz-file-preview')
        ref = file.previewElement.querySelectorAll('[data-dz-thumbnail-bg]')
        for (j = 0, len = ref.length; j < len; j++) {
          thumbnailElement = ref[j]
          thumbnailElement.alt = file.name
          thumbnailElement.style.backgroundImage = `url('${dataUrl}')`
        }
        return setTimeout((((_this) => {
          return () => {
            return file.previewElement.classList.add('dz-image-preview')
          }
        })(this)), 1)
      }
    },

    validate () {
      this.organizationActivityInputs = this.organizationActivityInputs.map((input) => {
        return {
          ...input,
          isValid: (input.isRequired && !!input.value) || !input.isRequired
        }
      })

      this.dropZones = this.dropZones.map((input) => {
        return {
          ...input,
          isValid: (input.isRequired && !!input.value && input.value.length) || !input.isRequired
        }
      })

      if (this.organizationActivityInputs.some((input) => !input.isValid)) {
        return false
      }

      if (this.dropZones.some((input) => !input.isValid)) {
        return false
      }

      return true
    }
  },

  components: {
    'vue-dropzone': vue2Dropzone,
    InputWithLabel
  },

  mixins: [submitAppealFields]
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  section {
    display: flex;
    flex-direction: column;

    > .comment__text {
      width: 40.93rem;
      font-size: .875rem;
      line-height: 1.375rem;
      color: $mainFontColor;
      margin-bottom: 1.25rem;
    }

    > .check-box__container {
      display: flex;
      align-items: center;
      margin-bottom: 2.5rem;

      > input[type="text"] {
        width: 62.5rem;
        margin-left: auto;
      }

      > label {
        margin: .1rem 2rem 0 0;
        font-size: .75rem;
        line-height: 1.125rem;
        color: #003E78;
        opacity: .6;
      }

      > input[type="radio"] {
        margin-right: .75rem;
        width: 1.25rem;
        height: 1.25rem;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: white;
          border-radius: 50%;
          border: .09rem solid $neutral-secondary;
        }

        &:checked:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          background-color: white;
          border-radius: 50%;
          border: .375rem solid $primary-color;
        }
      }
    }
  }
p{
  text-align: left;
  font-size: .875rem;
  line-height: 1.375rem;
  color: #1F3245;
  span{
    color: #EB494E;
  }
}
.block_file{
    width: 100%;
    height: 15.25rem;
    border: .125rem dashed #95b0c7;
    border-radius: .19rem;
    padding: .625rem;
    margin-right: 0 !important;
}
  .not-valid__block_file {
    border: .125rem dashed red;
  }
</style>
