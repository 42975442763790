<template>
  <section>
    <h2>
      Расскажите о деятельности вашей организации
    </h2>

    <div class="inputs__container">
      <input-with-label v-for="(input,index) in organizationActivityInputs.slice(0,6)" :input="input"
          :key="`formFieldOrganizationActivityInputs1${index}`" @inputValue="inputValue($event, input)"/>

      <p class="dropzone__label">
        Прикрепите ваше фото <span>*</span>
      </p>

      <div :class="['block_file', {'not-valid__block_file': !dropZones[0].isValid}]">
          <vue-dropzone ref="myVueDropzone" id="customdropzone"
                        :options="dropzoneOptions"
                        :useCustomSlot="true"
                        :value="dropZones[0].value"
                        :include-styling="false"
                        @vdropzone-file-added="vfileAdded($event, 0)"
                        @vdropzone-removed-file="removedFile($event, 0)"
                        @vdropzone-thumbnail="thumbnail">
              <div class="admin_dropzone" v-show="!dropZones[0].value.length">
                  <div id="icon"></div>
                  <p id="text">Перетащите файлы с компьютера или нажмите кнопку загрузить</p>
                  <span id="doc"> (форматы png, jpg, pdf)</span>
                  <button id="button">Загрузить</button>
              </div>
          </vue-dropzone>
      </div>

      <p class="checkboxes__title">
        Кто вас поддерживает и как?
      </p>

      <div class="checkboxes__container">
        <div class="column">

          <div class="support__container" v-for="(checkbox, index) in supportCheckboxes.slice(0,5)" :key="`supportCheckbox${index}`">

            <div class="checkbox">
              <input type="checkbox" :name="checkbox.name" :id="checkbox.name" v-model="checkbox.value">
              <label :for="checkbox.name">
                {{ checkbox.label }}
              </label>
            </div>

            <div class="inputs__container" v-if="checkbox.value">
              <input type="text" placeholder="Название организации" class="input blue" v-model="checkbox.nameValue">

              <input type="text" placeholder="Вид поддержки" class="input blue" v-model="checkbox.kindValue">
            </div>
          </div>

        </div>

        <div class="column">
          <div class="support__container" v-for="(checkbox, index) in supportCheckboxes.slice(5)" :key="`supportCheckbox${index}`">

            <div class="checkbox">
              <input type="checkbox" :name="checkbox.name" :id="checkbox.name" v-model="checkbox.value">
              <label :for="checkbox.name">
                {{ checkbox.label }}
              </label>
            </div>

            <div class="inputs__container" v-if="checkbox.value">
              <input type="text" placeholder="Название организации" class="input blue" v-model="checkbox.nameValue">

              <input type="text" placeholder="Вид поддержки" class="input blue" v-model="checkbox.kindValue">
            </div>
          </div>
        </div>
      </div>

      <input-with-label v-for="(input,index) in organizationActivityInputs.slice(6)" :input="input"
          :key="`formFieldOrganizationActivityInputs2${index}`" @inputValue="inputValue($event, input)"/>
    </div>
  </section>
</template>

<script>
import InputWithLabel from './InputWithLabel'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import submitAppealFields from '@/mixins/submitAppealFields'

export default {
  name: 'ThirdStep',

  props: {
    projectAppealData: {
      type: Object,
      required: false,
      default: null
    }
  },

  data () {
    return {
      dropzoneOptions: {
        url: '/api/upload-file',
        thumbnailWidth: 150,
        maxFilesize: 200,
        maxFiles: 10,
        addRemoveLinks: true,
        parallelUploads: 1,
        headers: { 'Content-Type': 'multipart/form-data' },
        paramName: 'file',
        previewTemplate: this.template(),
        accept: function (file, done) {
          if (file.name !== 0) {
            // var NameFile = file.name
          } else {
            done()
          }
        }
      },
      organizationActivityInputs: [
        {
          name: 'activity_about',
          label: 'Расскажите о себе кратко и содержательно, указав опыт общественной деятельности, реализуемые и реализованные проекты, с указанием сроков реализации и Вашей роли в процессе организации данных проектов',
          placeholder: 'Ваши обязанности',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'activity_profile_instagram',
          label: 'Ваш профиль в ВКонтакте',
          placeholder: 'https://vk.com/name',
          style: {
            width: '32%',
            marginRight: '2%'
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'activity_profile_facebook',
          label: 'Ваш профиль в Facebook',
          placeholder: 'https://facebook.com/name',
          style: {
            width: '32%',
            marginRight: '2%'
          },
          isRequired: true,
          isValid: true,
          value: null
        },
        {
          name: 'activity_profile_other',
          label: 'Другая соц. сеть',
          placeholder: 'Одноклассники, Instagram, Twitter',
          style: {
            width: '32%'
          },
          isRequired: false,
          isValid: true,
          value: null
        },
        // TODO : dropzone
        {
          name: 'activity_comment_activity_significant',
          label: 'Можно ли считать Вашу общественную деятельность социально-значимой? Если да, то почему?',
          placeholder: 'Ваш комментарий',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: false,
          isValid: true,
          value: null
        },
        {
          name: 'activity_comment_participation_organizations',
          label: 'Состоите ли Вы в каких-либо общественных организациях, объединениях и движениях? Если да, то укажите в каких',
          placeholder: 'Ваш комментарий',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: false,
          isValid: true,
          value: null
        },
        // TODO : checboxes
        {
          name: 'activity_mass_media_publications',
          label: 'У вас есть публикации в СМИ? Если да, то укажите ссылки на публичные выступления, публикации в СМИ, социальных сетях...',
          placeholder: 'Ваш комментарий',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: false,
          isValid: true,
          value: null
        },
        {
          name: 'activity_awards',
          label: 'Если у вас имеются личные награды и достижения, то укажите их',
          placeholder: 'Ваш комментарий',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: false,
          isValid: true,
          value: null
        },
        {
          name: 'activity_joint_activities',
          label: 'Планируете ли Вы в ближайший год какие-либо совместные активности с организациями / некоммерческими проектами, бизнесом и государством? Если да, то перечислить какие.',
          placeholder: 'Ваш комментарий',
          style: {
            width: '100%',
            marginRight: 0
          },
          isRequired: false,
          isValid: true,
          value: null
        }
      ],
      supportCheckboxes: [
        {
          name: 'nko',
          label: 'НКО в Вашем регионе',
          value: false,
          nameValue: '',
          kindValue: ''
        },
        {
          name: 'nko_other',
          label: 'НКО в других регионах',
          value: false,
          nameValue: '',
          kindValue: ''
        },
        {
          name: 'reg_pal',
          label: 'Региональная Общественная палата',
          value: false,
          nameValue: '',
          kindValue: ''
        },
        {
          name: 'obs_pal',
          label: 'Общественная палата РФ',
          value: false,
          nameValue: '',
          kindValue: ''
        },
        {
          name: 'mass_media',
          label: 'СМИ',
          value: false,
          nameValue: '',
          kindValue: ''
        },

        {
          name: 'mun_vl',
          label: 'Муниципальные власти',
          value: false,
          nameValue: '',
          kindValue: ''
        },
        {
          name: 'reg_vl',
          label: 'Региональные власти',
          value: false,
          nameValue: '',
          kindValue: ''
        },
        {
          name: 'fed_vl',
          label: 'Федеральные власти',
          value: false,
          nameValue: '',
          kindValue: ''
        },
        {
          name: 'bus_str',
          label: 'Бизнес-структуры',
          value: false,
          nameValue: '',
          kindValue: ''
        }
      ],
      dropZones: [
        {
          name: 'activity_photo',
          value: [],
          label: 'Прикрепите Ваше фото',
          isRequired: true,
          isValid: true,
          formats: '(форматы png, jpg, pdf)'
        }
      ]
    }
  },

  mounted () {
    this.setValues()
  },

  watch: {
    projectAppealData: {
      deep: true,
      handler () {
        this.setValues()
      }
    }
  },

  methods: {
    setValues () {
      if (this.projectAppealData.activity) {
        for (const key in this.projectAppealData.activity) {
          this.organizationActivityInputs = this.organizationActivityInputs.map((input) => {
            return {
              ...input,
              value: (input.name === `activity_${key}`) ? this.projectAppealData.activity[key] : input.value
            }
          })
          this.dropZones = this.dropZones.map((input) => {
            if (input.name === `activity_${key}`) {
              return {
                ...input,
                value: this.projectAppealData.activity[key] ? this.projectAppealData.activity[key].map((file) => {
                  const url = file
                  let fileName = url.split('/')

                  fileName = fileName[fileName.length - 1]

                  let extension = fileName.split('.')
                  extension = extension[extension.length - 1]

                  this.$refs.myVueDropzone.manuallyAddFile({
                    size: 123,
                    name: fileName,
                    type: extension,
                    accepted: true,
                    file_url: url
                  }, url)
                  return url
                }) : []
              }
            } else {
              return input
            }
          })
        }

        this.organizationActivityInputs = this.organizationActivityInputs.map((input) => {
          return {
            ...input,
            value: this.projectAppealData[input.name] ? this.projectAppealData[input.name] : input.value
          }
        })

        this.dropZones = this.dropZones.map((input) => {
          return {
            ...input,
            value: this.projectAppealData[input.name] ? this.projectAppealData[input.name] : input.value
          }
        })

        if (this.projectAppealData.activity.support) {
          const storedSupport = JSON.parse(this.projectAppealData.activity.support)
          this.supportCheckboxes = this.supportCheckboxes.map(supportCheckbox => {
            const checkboxIndex = storedSupport.findIndex(checkbox => checkbox.name === supportCheckbox.name)

            return {
              ...supportCheckbox,
              value: checkboxIndex !== -1 ? storedSupport[checkboxIndex].value : false,
              nameValue: checkboxIndex !== -1 ? storedSupport[checkboxIndex].nameValue : null,
              kindValue: checkboxIndex !== -1 ? storedSupport[checkboxIndex].kindValue : null
            }
          })
        }
      }
    },

    inputValue (value, input) {
      input.value = value
    },

    emitData () {
      this.$emit('emitData', Object.assign(
        this.getFieldsForSubmit(this.organizationActivityInputs),
        this.getFieldsForSubmit(this.dropZones),
        {
          activity_support: JSON.stringify(this.supportCheckboxes)
        }
      ))
    },

    vfileAdded (file, i) {
      var files = this.dropZones[i].value
      files.push(file)
      this.dropZones[i].value = files
    },

    removedFile (file, i) {
      var array = this.dropZones[i].value
      var newArr = []
      array.forEach(item => {
        if ('upload' in file) {
          if (file.upload.uuid !== item.upload.uuid) {
            newArr.push(item)
          }
        } else if ('file_url' in file) {
          if (file.file_url !== item) {
            newArr.push(item)
          }
        }
      })
      /*
      if (newArr.length === 0) {
        document.getElementById('icon').style.display = 'block'
        document.getElementById('text').style.display = 'block'
        document.getElementById('doc').style.display = 'block'
      }
      */
      this.dropZones[i].value = newArr
    },

    template () {
      return `<div class="dz-preview dz-file-preview">
                <div class="dz-image">
                    <div data-dz-thumbnail-bg></div>
                </div>
            </div>`
    },

    thumbnail (file, dataUrl) {
      var j, len, ref, thumbnailElement
      if (file.previewElement) {
        /*
        document.getElementById('icon').style.display = 'none'
        document.getElementById('text').style.display = 'none'
        document.getElementById('doc').style.display = 'none'
        */
        file.previewElement.classList.remove('dz-file-preview')
        ref = file.previewElement.querySelectorAll('[data-dz-thumbnail-bg]')
        for (j = 0, len = ref.length; j < len; j++) {
          thumbnailElement = ref[j]
          thumbnailElement.alt = file.name
          thumbnailElement.style.backgroundImage = `url('${dataUrl}')`
        }
        return setTimeout((((_this) => {
          return () => {
            return file.previewElement.classList.add('dz-image-preview')
          }
        })(this)), 1)
      }
    },

    validate () {
      this.organizationActivityInputs = this.organizationActivityInputs.map((input) => {
        return {
          ...input,
          isValid: (input.isRequired && !!input.value) || !input.isRequired
        }
      })

      this.dropZones = this.dropZones.map((input) => {
        return {
          ...input,
          isValid: (input.isRequired && !!input.value && input.value.length) || !input.isRequired
        }
      })

      if (this.organizationActivityInputs.some((input) => !input.isValid)) {
        return false
      }

      if (this.dropZones.some((input) => !input.isValid)) {
        return false
      }

      return true
    }
  },

  components: {
    'vue-dropzone': vue2Dropzone,
    InputWithLabel
  },

  mixins: [submitAppealFields]
}

</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  section {
    display: flex;
    flex-direction: column;

    > .comment__text {
      width: 40.93rem;
      font-size: .875rem;
      line-height: 1.375rem;
      color: $mainFontColor;
      margin-bottom: 1.25rem;
    }

    .checkboxes__title, .dropzone__label {
      font-size: .875rem;
      line-height: 1.375rem;
      color: $mainFontColor;
    }

    .dropzone__label {
      margin-bottom: .31rem;

      > span {
        color: #EB494E;
      }
    }

    .checkboxes__container {
      width: 100%;
      display: flex;

        > .column {
          display: flex;
          flex-direction: column;
          width: 40rem;
          margin-right: 2.187rem;

          > .support__container {
            display: flex;
            flex-direction: column;

            > .checkbox {
              display: flex;
              align-items: center;

              > label {
                margin: 0;
                font-size: .75rem;
                line-height: 1.125rem;
                color: #003E78;
              }

              > input[type="checkbox"] {
                margin-right: .75rem;
                width: 1.25rem;
                height: 1.25rem;
                position: relative;
                /*visibility: hidden;*/

                &:before {
                  content: '';
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: -.09rem;
                  left: -.09rem;
                  border: .09rem solid $neutral-secondary;
                  background-color: white;
                  border-radius: .25rem;
                  box-sizing: content-box;
                }

                &:checked:before {
                  background-color: #246CB7;
                  border: .09rem solid #246CB7;
                }

                &:checked:after {
                  content: url('../../assets/icons/check_white.svg');
                  position: absolute;
                  /*width: 100%;*/
                  /*height: 100%;*/
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%,-50%);
                  object-fit: contain;
                  object-position: center;
                }
              }
            }

            > .inputs__container {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              margin-bottom: 0;
              margin-top: 1rem;

              > input {
                margin-bottom: 0;
                margin-right: 0;
                width: 19.25rem;

                &:first-child {
                  margin-right: 1rem;
                }
              }
            }
          }

          > * {
            margin-bottom: 1.5rem;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        > .second-column {
          input:first-child {
            margin-top: 2.75rem;
          }

          input:last-child {
            margin-top: auto;
          }
        }
    }
  }

  .block_file{
    width: 100%;
    height: 15.25rem;
    border: .125rem dashed #95b0c7;
    border-radius: .19rem;
    padding: .625rem;
}

  .not-valid__block_file {
    border: .125rem dashed red;
  }
</style>
