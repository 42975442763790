<template>
  <div class="form__field" :style="getFormFieldWith">
    <label :for="input.name">
      {{ input.label }}
      <span v-if="input.isRequired">
        *
      </span>
    </label>
    <input v-if="!input.type || (input.type !== 'select' && input.type !== 'date')"
           :type="input.type ? input.type : 'text'"
           :id="input.name"
           :name="input.name"
           :placeholder="input.placeholder ? input.placeholder : ''"
           @input="inputValue($event.target.value)"
           :style="getIsValidStyle"
           :ref="input.name"
           class="input blue">

    <custom-select v-else-if="input.type === 'select'"
                   :title="input.placeholder"
                   :key="`inclusion1`"
                   class="select"
                   :list="input.list"
                   :ref="input.name"
                   @InputsContent="setInclusion"
                   :style="getIsValidStyle"
                   :placeholder="input.placeholder ? input.placeholder : ''"/>

    <datepicker v-else
                input-class="input blue"
                v-model="dateValue"
                @input="inputDate"
                :style="getIsValidStyle"
                :language="ru"></datepicker>
  </div>
</template>

<script>
import CustomSelect from '../forms/CustomSelect'
import Datepicker from 'vuejs-datepicker'
import { ru } from 'vuejs-datepicker/dist/locale'

export default {
  name: 'InputWithLabel',

  data () {
    return {
      ru: ru,
      dateValue: null
    }
  },

  props: {
    input: {
      type: Object,
      required: true
    }
  },

  watch: {
    input: {
      deep: true,
      handler () {
        if (this.input.type !== 'date' && this.input.type !== 'select' && this.input.value) {
          this.$refs[this.input.name].setAttribute('value', this.input.value)
        } else if (this.input.type === 'select' && this.input.value) {
          // let element = this.input.list.filter((element) => element.id === this.input.value)
          const index = this.input.list.findIndex((element) => element.id === this.input.value)

          if (index >= 0) {
            // element = element[0]
            // console.debug(element)
            this.$refs[this.input.name].changeTitle(this.input.list[index].title, index)
          }
        } else if (this.input.type === 'date' && this.input.value) {
          this.dateValue = this.input.value
        }
      }
    }
  },

  methods: {
    setInclusion (value) {
      if (value) {
        this.$emit('inputValue', value.id)
      }
    },

    inputValue (value) {
      this.$emit('inputValue', value)
    },

    inputDate (value) {
      this.$emit('inputValue', `${value.getUTCFullYear()}-${this.getDoubleDigit(value.getUTCMonth() + 1)}-${this.getDoubleDigit(value.getUTCDate())}`)
    },

    getDoubleDigit (digit) {
      return digit < 10 ? '0' + digit : digit
    }
  },

  computed: {
    getFormFieldWith () {
      return this.input.style ? this.input.style : {}
    },

    getIsValidStyle () {
      return {
        border: `1px solid ${this.input.isValid ? '#EEF4FA' : '#EB494E'}`
      }
    }
  },

  components: {
    CustomSelect,
    Datepicker
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  .form__field {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 19.375rem;

    > label {
      text-align: left;
      font-size: .875rem;
      line-height: 1.375rem;
      color: $mainFontColor;

      > span {
        color: $danger-color;
      }
    }
  }
  @media screen and (max-width: 768px){
    .form__field{
      width: 100%;
      margin-right: 0 !important;
    }
    .custom-select{
      width: 100%;
    }
  }
</style>
