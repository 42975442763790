<template>
  <article :style="getBackgroundImage">
    {{ index + 1 }}
  </article>
</template>

<script>
export default {
  name: 'TimeLinePoint',
  props: {
    index: {
      type: Number,
      required: true
    },
    isPassed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    getBackgroundImage () {
      return {
        backgroundImage: `url(/assets/icons/circle_with_lines${this.isPassed ? '' : '_disabled'}.svg)`
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  article {
    width: 5.25rem;
    height: 5.25rem;
    background-size: contain;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: bold;
    font-size: 1.375rem;
    line-height: 2rem;
    color: $primary-color;
  }
</style>
